@import 'styles/partials/colors';

.green,
.red,
.yellow,
.blue {
  transition: color 0.15s ease-in-out;
}

.green {
  color: $accent-green;

  &:hover,
  &:focus,
  &:active {
    color: $accent-light-green;
  }
}

.red {
  color: $accent-dark-red;

  &:hover,
  &:focus,
  &:active {
    color: $accent-light-red;
  }
}

.yellow {
  color: $accent-yellow;

  &:hover,
  &:focus,
  &:active {
    color: $primary-orange;
  }
}

.blue {
  color: $accent-dark-blue;

  &:hover,
  &:focus,
  &:active {
    color: $secondary-blue;
  }
}
