@import 'styles/partials/colors';
@import 'styles/partials/fonts';

.mainContainer {
  display: flex;
  font-family: $body-font;
  min-height: 100vh;
  background: $accent-snow;
  position: absolute;
  width: 100%;
  overflow-y: clip;

  .content {
    background: $accent-snow;
    display: inline-table;
    position: relative;
    margin-top: 80px;

    @media print {
      margin-top: 0;
    }
  }

  .main {
    display: block;
    padding: 0;
  }

  &.withNav {
    display: flex;
    left: 85px;
    width: calc(100% - 85px);
  }

  .container {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;
  }

  .containerFullWidth {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: flex;

    .content {
      // height: 100vh;
      justify-content: stretch;
      padding-top: 0;
    }

    .main {
      align-items: flex-start;
    }

    &.withNav {
      display: flex;
      left: 85px;
      width: calc(100% - 85px);

      .content {
        flex: 1;
      }
    }
  }
}

.disableFocusOutline {
  button,
  a {
    &:focus {
      outline: none;
    }
  }
}
