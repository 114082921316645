@import 'styles/partials/fonts';
@import 'styles/partials/colors';

.submitLandingPage {
  margin-top: 164px;

  .noSubmitLandingPageFontWeight {
    font-weight: 400;  
  }

  span {
    font-weight: 600;
  }

  h2 {
    color: $primary-blue;
    margin-bottom: 1em;
    font-size: 30px;
    font-family: $heading-font;
    font-weight: 400;
  }

  h3 {
    color: $primary-blue;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
  }

  .topSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .bottomSection {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 241px;
    }
  }

  .pdfDiv {
    display: flex;
    margin-top: 60px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 29px;

    svg {
      margin-right: 10px;
    }

    &:last-of-type {
      margin-bottom: 80px;
    }
  }

  .viewDownload {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    button {
      text-decoration: none;
      color: $secondary-blue;
      line-height: 29px;
    }
  }

  .pipe {
    color: $accent-grey;
    margin: 0 16px;
  }

  .noPDFSpacing {
    margin-top: 40px;
  }

  .nftBack {
    margin-top: 10px;
  }

  .advancedElectionDesc {
    font-size: 1.25rem;
    margin-top: 3rem;

    > span {
      font-weight: 400;
    }
  }
}

.submitLandingPageWithNewNav {
  margin-top: 0;
}
