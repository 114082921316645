@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';

.rc-dialog {
  position: relative;
  width: auto;
  text-align: center;
}

.rc-dialog-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-dialog-title {
  padding-top: 52px;
}

.rc-dialog-content {
  position: relative;
  background-color: $white;
  border: 0;
  background-clip: padding-box;
}

.rc-dialog-close {
  border: 0;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 18px;
  padding: 0;

  svg {
    width: 32px;
    height: 32px;

    path {
      fill: $primary-blue;
    }
  }

  div {
    display: flex;
  }
}

.rc-dialog-close-x::after {
  content: '×';
}

.rc-dialog-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  text-decoration: none;
}

.rc-dialog-body {
  padding: 10px 46px 32px;
}

.rc-dialog-footer {
  display: flex;
  justify-content: center;
  padding: 10px 20px 40px;
  border-radius: 0 0 5px 5px;

  button {
    width: fit-content;

    &:first-of-type {
      margin-right: 8px;
    }

    &:last-of-type {
      margin-left: 8px;
    }
  }
}

.rc-dialog-zoom-enter,
.rc-dialog-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.08, .82, .17, 1);
  animation-play-state: paused;
}

.rc-dialog-zoom-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.6, .04, .98, .34);
  animation-play-state: paused;
}

.rc-dialog-zoom-enter.rc-dialog-zoom-enter-active,
.rc-dialog-zoom-appear.rc-dialog-zoom-appear-active {
  animation-name: rcDialogZoomIn;
  animation-play-state: running;
}

.rc-dialog-zoom-leave.rc-dialog-zoom-leave-active {
  animation-name: rcDialogZoomOut;
  animation-play-state: running;
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1, 1);
  }

  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

.rc-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $accent-mine-shaft;
  background-color: rgba($accent-mine-shaft, .6);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 2999;
}

.rc-dialog-mask-hidden {
  display: none;
}

.rc-dialog-fade-enter,
.rc-dialog-fade-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.55, 0, .55, .2);
  animation-play-state: paused;
}

.rc-dialog-fade-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.55, 0, .55, .2);
  animation-play-state: paused;
}

.rc-dialog-fade-enter.rc-dialog-fade-enter-active,
.rc-dialog-fade-appear.rc-dialog-fade-appear-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}

.rc-dialog-fade-leave.rc-dialog-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
