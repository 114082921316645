@import 'styles/partials/colors';

.navBackButton {
  button {
    span {
      padding-left: 10px;
    }

    svg {
      path {
        stroke: $secondary-blue;
      }
    }

    &:hover,
    &:focus {
      svg {
        path {
          stroke: $primary-blue;
        }
      }
    }
  }
}
