@import 'styles/partials/fonts';
@import 'styles/partials/colors';

// This selector is put here to support ie11, it shouldnt' be used for anythign else
/* stylelint-disable selector-max-id*/
#__next {
  display: grid;
  height: 100vh;
  width: 100vw;
  -ms-grid-columns: 100%; // stylelint-disable-line property-no-vendor-prefix
}

.bp4-portal {
  z-index: 1000000000;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  @media print {
    -webkit-print-color-adjust: exact; /* stylelint-disable-line property-no-vendor-prefix */
  }
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.6rem;
  font-family: $body-font;
  font-style: normal;
  font-weight: normal;
  background-color: $white;
  color: $primary-black;
  overscroll-behavior: none;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes

  @media (max-width: $medium) {
    font-size: 1.1rem;
  }
}

h1 {
  margin: 0.6rem 0 1.5rem;
  padding: 0;
  font-family: $heading-font;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.7rem;
  color: $primary-orange;

  @media (max-width: $medium) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

h2 {
  margin: 0 0 1.4rem;
  padding: 0;
  font-family: $heading-font;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: $primary-blue;

  @media (max-width: $medium) {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}

h3 {
  margin: 0 0 0.5rem;
  padding: 0;
  font-family: $body-font;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: $primary-blue;

  &.black {
    color: $primary-black;
  }

  @media (max-width: $medium) {
    font-size: 1.3rem;
    line-height: 1.7rem;
  }
}

h4 {
  margin: 0 0 0.5rem;
  padding: 0;
  font-family: $body-font;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: $black;

  @media (max-width: $medium) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

h5 {
  margin: 0 0 0.5rem;
  padding: 0;
  font-family: $body-font;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6rem;
  color: $black;

  @media (max-width: $medium) {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}

a {
  font-weight: normal;
  transition: color 0.15s ease-in-out;
  color: $secondary-blue;
  text-decoration: underline;
  cursor: pointer;

  &:focus,
  &:hover {
    color: $primary-blue;
  }
}

img {
  max-width: 100%;
  display: block;
  width: 100%;
}

p {
  font-family: $body-font;
  width: 100%;
  margin: 0 0 1.2rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

div.flex,
div.flex > div {
  display: flex;
}

.bold {
  font-weight: 700;
}

.semiBold {
  font-weight: 600;
}

.hidden {
  visibility: hidden;

  &Remove {
    display: none;
  }
}

table {
  tr {
    .errorMsg {
      margin-top: 0;
    }
  }
}
