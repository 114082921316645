@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';


.navHeader {
  @media print {
    display: none;
  }
  z-index: 2998;
  height: 80px;
  min-height: 80px;
  box-shadow: 0 3px 6px rgba($black, 0.08);
  font-size: 24px;
  background: $white;
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  color: $primary-blue;
  width: 100%;
  
  &.withNav {
    width: calc(100% - 85px);
  }
  
  .logo {
    display: flex;
    align-items: center;
    color: $primary-blue;
    font-weight: 700;
    text-decoration: none;
    padding: 5px 36px;
    min-width: 88px;

    div {
      display: flex;
    }

    &Text {
      margin-left: 8px;
      white-space: nowrap;
    }

    &:visited,
    &:active {
      text-decoration: none;
    }

    @media (max-width: $medium) {
      padding: 26px 12px;
    }

    @media (max-width: $xlarge) {
      padding: 12px 5px;
    }
  }

  .linksRow {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 36px;
    font-size: 17px;
    line-height: 18px;
    color: $primary-black;
    border: 0;
    background-color: $white;
    text-decoration: none;

    @media (max-width: $medium) {
      padding: 0 12px;
    }
    @media (max-width: $xlarge) {
      padding: 0 15px;
    }

    &Disabled {
      font-weight: 600;
      cursor: default;
    }
  }

  .divider {
    border-left: 1px solid $accent-grey;
    height: 55%;
  }

  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .memberNum {
      color: $secondary-light-grey;
      margin-left: 66px;
      font-size: 17px;
    }

    .employerName {
      font-size: 17px;
      color: $primary-black;
      @media (max-width: $xlarge) {
        font-size: 15px;
      }
    }

    .employerName + .memberNum {
      margin-left: 8px;
    }

    @media (max-width: $medium) {
      padding: 0 12px;

      .employerName {
        font-size: 15px;
      }
    }

    @media (max-width: $xlarge) {
      font-weight: 200;
    }
    
  }

  a,
  div {
    &.member {
      padding: 0 22px;
      height: 100%;
      text-decoration: none;
    }
  }

  .emailNotificationRow {
    display: flex;
    align-items: center;

    > button {
      width: 22px;
      margin: 0 44px 0 10px;
      padding: 0;
      background-color: transparent;

      div {
        display: flex;
      }

      &:hover {
        background: transparent;
      }
    }

    .spinner {
      margin: 0 8px;
    }
  }

  .learningCenterLink button {
    text-decoration: none;
    color: $primary-black;

    &:hover {
      color: $primary-black; 
    }

    &:focus {
      color: $primary-black;
    }

    &:hover:disabled {
      color: $accent-silver;
    }
  }

  .disabledLogout {
    /* stylelint-disable-next-line declaration-no-important */
    color: $accent-silver !important;
  }
  
  .contactRow {
    display: flex;
    align-items: center;

    > button {
      width: 100%;
      margin: 0;
      padding: 0;

      div {
        display: flex;
      }

      &:hover {
        background: transparent;
      }
    }
  }
}
