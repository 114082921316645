@import 'styles/partials/colors';
@import 'styles/partials/fonts';

.textArea {
  width: 100%;

  textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 16px;
    color: $primary-black;
    background-color: $accent-grey-09;
    border: 1px solid $secondary-grey;
    border-radius: 5px;

    &.isFieldError {
      border-color: $accent-red;
      background-color: $accent-white-rose;
    }

    &.isFieldNonResizable {
      resize: none;
    }

    &.isFieldOnlyVerticallyResizable {
      resize: vertical;
    }

    &.isFieldOnlyHorizontallyResizable {
      resize: horizontal;
    }

    &::placeholder {
      color: $accent-silver;
    }

    &[disabled] {
      background-color: $accent-grey-02;
      border: 1px solid $accent-silver;
      color: $accent-silver;
    }
  }

  &Label {
    display: block;
    font-family: $body-font;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .errorMessage {
    color: $accent-red;
  }

  .counter {
    color: $secondary-grey;
    float: right;
  }
}
