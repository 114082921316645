@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';

.nav {
  @media print {
    display: none;
  }

  z-index: 2999;
  position: fixed;
  flex-direction: column;
  background-color: $primary-blue;
  min-width: 85px;
  overflow-x: auto;
  top: 0;
  left: 0;
  height: 100%;

  @media (max-height: $medium) {
    overflow-y: scroll;
  }

  a,
  button {
    display: flex;
    align-items: center;
    background-color: $primary-blue;
    color: $accent-snow;
    border: 0;
    border-radius: 0;
    padding: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    height: 80px;
    position: relative;
    text-decoration: none;

    .messagesCount,
    .reportsCount,
    .returnedRequests,
    .nftCount {
      color: $primary-blue;
      margin-top: 10px;
    }

    &:hover {
      background-color: $secondary-blue;
      text-decoration: none;

      .messagesCount,
      .reportsCount,
      .returnedRequestsCount,
      .nftCount {
        color: $secondary-blue;
      }
    }

    &:focus {
      text-decoration: none;
    }

    &:disabled {
      background-color: $accent-silver;
      border: 1.5px solid $accent-silver;
      color: $accent-snow;
      pointer-events: none;
    }
  }
}

.messagesCount,
.reportsCount,
.returnedRequestsCount,
.nftCount {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 41px;
  top: 16px;
  background-color: $accent-snow;
  color: $primary-blue;
  height: 17px;
  min-width: 17px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 700;

  &Text {
    padding: 0 6px;
  }
}

.buttonWrapper {
  &.active {
    background-color: $secondary-blue;

    a {
      background-color: $secondary-blue;

      .messagesCount,
      .reportsCount,
      .returnedRequestsCount,
      .nftCount {
        color: $secondary-blue;
      }
    }

    .text {
      color: $white;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;

    .icon {
      svg {
        height: 20px;
        width: 20px;
      }
    }

    .text {
      font-size: 12px;
      text-align: center;
      line-height: 14px;
      margin-top: 2px;
      color: $white;
      max-width: 70px;
    }

    .messagesCount,
    .reportsCount,
    .nftCount {
      top: 0;
      left: 45px;
    }

    .reportsCount {
      top: 5px;
    }

    .returnedRequestsCount {
      top: 3px;
      left: 40px;
    }
  }
}
