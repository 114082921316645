@import 'styles/partials/colors';

.toggleSwitch {
  display: inline-block;
  vertical-align: middle;

  .checkbox {
    display: none;
  }

  .switch {
    position: relative;
    width: 40px;
    height: 24px;
    border-radius: 20%;
    transition: background-color 100ms ease-out;
    z-index: 1;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      background-color: inherit;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      z-index: 2;
    }

    &::before {
      left: -4px;
    }

    &::after {
      right: -4px;
    }
  }

  .toggleButton {
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: $white;
    border-radius: 50%;
    transition: transform 100ms ease-in-out;
    z-index: 3;
    border: 1px solid $accent-grey;
  }

  .switchOff {
    background-color: $accent-grey;

    .toggleButton {
      transform: translateX(-6px);
    }
  }

  .switchOn {
    background-color: $primary-blue;

    .toggleButton {
      transform: translateX(24px);
    }
  }


  .disabled {
    background-color: $accent-grey;
    cursor: not-allowed;
  
    &::before {
      content: '';
      background-color: $accent-grey;
      cursor: not-allowed;
    }
  }

}
