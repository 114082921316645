@import 'styles/partials/colors';
@import 'styles/partials/fonts';
@import 'styles/partials/mixins';
@import 'styles/partials/breakpoints';

@mixin table-media-query($breakpoint, $font-size, $padding) {
  @media (max-width: $breakpoint) {
    tr {
      td,
      th {
        padding: $padding;
        font-size: $font-size;
        line-height: calc(#{$font-size} * 1.3);

        button,
        a,
        div,
        span {
          font-size: $font-size;
          line-height: calc(#{$font-size} * 1.3);
        }
      }

      th {
        sub {
          font-size: calc(#{$font-size} - 2px);
          line-height: calc((#{$font-size} - 2px) * 1.3);
        }
      }
    }
  }
  @media print {
    td {
      font-size: 0.75rem !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.table {
  @include table-media-query($xlarge, 1rem, 15px 12px);
  @include table-media-query($large, 0.95rem, 15px 8px);
  @include table-media-query($medium, 0.9rem, 15px 6px);
  width: 100%;
  text-align: left;
  vertical-align: middle;
  font-size: 18px;

  sub {
    font-size: 16px;
    vertical-align: unset;
    position: inherit;
    bottom: 0;
  }

  input,
  select {
    margin-bottom: 0;
  }

  td {
    &.accent {
      background-color: $accent-white-smoke;
    }
  }

  &.bg-blue {
    background-color: $accent-pale-blue;
  }

  &.disabled {
    opacity: 0.3;
  }

  &.with-colored-header-row {
    thead {
      tr {
        background-color: $accent-white-smoke;
      }
    }

    &.header-blue {
      thead {
        tr {
          background-color: $secondary-blue;
          color: $white;
        }
      }
    }
  }

  &.even-width-columns {
    table-layout: fixed;
  }

  tr.with-colored-rows:nth-child(even) {
    background-color: $accent-white-smoke;
  }

  tr.with-colored-rows-opposite:nth-child(odd) {
    background-color: $accent-white-smoke;
  }

  &.with-column-borders {
    td,
    th {
      border: 1px solid $accent-grey;
      position: inherit;

      &.error {
        border: 1px solid $accent-red;
        background-color: rgba($accent-red, 0.1);
      }

      &.expandErrorHightlight {
        border-right: 1px solid $accent-grey;
      }
    }
  }

  &.bold-first-column {
    tbody {
      td:first-child,
      th {
        font-weight: 600;
      }
    }
  }

  &.with-table-border {
    border: 1px solid $accent-grey;
  }

  &.withAccent {
    border-top: 6px solid $secondary-blue;
  }

  &.with-row-borders {
    tr {
      border-top: 1px solid $accent-grey;
      border-bottom: 1px solid $accent-grey;
    }

    tr:first-child {
      border-top: 0;
      border-bottom: 0;
    }
  }

  &.with-row-borders-no-bottom {
    tr {
      border-top: 1px solid $accent-grey;
      border-bottom: 1px solid $accent-grey;
    }

    tr:first-child {
      border-top: 0;
      border-bottom: 0;
    }

    tr:last-child {
      border-top: 0;
      border-bottom: 0;
    }
  }

  &.with-first-row-border-top {
    tr:first-child {
      border-top: 1px solid $accent-grey;
    }
  }

  caption {
    &.table-caption {
      border: 1px solid $accent-grey;
      border-bottom: 0;
      padding: 10px 20px;
      background-color: $accent-white-smoke;
      font-weight: 600;
    }
  }

  thead {
    border-bottom: 1px solid $accent-grey;
    
    &.alignTop {
      vertical-align: top;
    }

    th {
      font-weight: 600;
    }
  }

  td,
  tbody {
    vertical-align: inherit;

    &.alignTop {
      vertical-align: top;
    }
  }

  th.hidden,
  td.hidden,
  tr.hidden {
    display: none;
  }

  td,
  th {
    padding: 15px 20px;
    position: relative;
  }

  .full {
    padding: 5px 20px;
    background-color: $accent-white-smoke;
    font-weight: 600;
  }

  .half {
    max-width: 50%;
  }

  .center {
    text-align: center;
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }

  .highlight {
    background-color: $accent-pale-blue;
  }

  .coloredCell {
    background-color: $accent-white-smoke;
  }

  .disabled {
    color: $accent-grey;
  }

  .highlight:first-of-type {
    box-shadow: inset 5px 0 0 0 $secondary-blue;
  }

  .fixedWidth {
    min-width: 100px;
  }

  .ellipsisOverflow {
    @include ellipsis-overflow;
  }

  .error {
    border: 1px solid $accent-red;
  }

  .expandErrorHightlight {
    border-right: 1px solid $accent-grey;
  }

  .yellowHighlight {
    background-color: $accent-pale-yellow;
  }
}
