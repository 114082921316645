@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';
@import 'styles/partials/constants';

.notificationContainer {
  border: 2px solid $secondary-yellow;
  background-color: $accent-floral-white;
  display: flex;
  margin: $container-spacing;
  padding: 20px 24px;
  margin-bottom: 20px;

  svg,
  img {
    margin-right: 15px;
  }

  svg {
    height: 22px;
    width: 22px;
    flex-shrink: 0;
  }

  img {
    height: 35px;
    width: 35px;
  }

  &.noMargin {
    margin: 0;
  }

  &.marginBottom {
    margin-bottom: 20px;
  }

  &.marginTop {
    margin-top: 20px;
  }

  &.inlineMini {
    font-size: 1rem;
    margin: 0;
    padding: 5px 13px 5px 10px;
    justify-content: center;
    border-radius: 20px;
    color: $black;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }
}

.notification {
  &MsgContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &Icon {
    display: flex;
    justify-content: flex-start;
    margin-top: 2px;

    div {
      display: flex;
    }
  }

  &Msg {
    margin: auto 0;

    &:last-child {
      p {
        margin-bottom: 0;
      }
    }

    strong {
      font-weight: 600;
    }
  }

  &Success {
    border: 2px solid $dark-green;
    background-color: $accent-panache;

    svg {
      color: $dark-green;
    }
  }

  &Warning {
    border: 2px solid $secondary-yellow;
    background-color: $accent-floral-white;

    svg {
      color: $secondary-yellow;
    }
  }

  &Info {
    border: 2px solid $secondary-blue;
    background-color: $accent-pale-blue;

    svg {
      color: $secondary-blue;
    }
  }

  &Error {
    border: 2px solid $accent-red;
    background-color: $accent-white-rose;

    svg {
      color: $accent-red;
    }
  }
}
