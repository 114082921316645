@use '../partials/colors';

/*
 * IMPORTANT: This file is only for features/components that require specific styling that cannot otherwise be styled through sx/css props.
 * Any styling must be scoped within a unique selector that is only used for that specific feature/component
 * e.g. .eap-date-picker { ... }
 */

.eap-date-picker {
  .bp4-icon {
    right: 0 !important; /* stylelint-disable-line declaration-no-important */

    &.bp4-icon-chevron-left,
    &.bp4-icon-chevron-right {
      color: colors.$secondary-blue;
    }
  }

  .bp4-datepicker-month-select,
  .bp4-datepicker-year-select {
    select {
      color: colors.$black;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      padding-right: 8px;
    }
  }
  
  .bp4-datepicker-year-select select option { 
    text-align: center;
  }

  .DayPicker-Caption {
    > .bp4-divider {
      border-bottom: 3px solid colors.$secondary-blue;
    }
  }

  .DayPicker-WeekdaysRow {
    .DayPicker-Weekday {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background-color: colors.$secondary-blue !important; /* stylelint-disable-line declaration-no-important */
    border-radius: 3px !important; /* stylelint-disable-line declaration-no-important */

    &:hover {
      opacity: 0.9;
    }
  }

  .bp4-datepicker-day-wrapper {
    font-size: 0.8125rem;
  }
}

.eap-date-picker-input {
  isolation: isolate;

  .bp4-input {
    padding-left: 40px !important; /* stylelint-disable-line declaration-no-important */
    height: 44px;
    border-radius: 5px;
    color: black;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #fbfbfb;
    border-width: 1px;
    border-style: solid;
    border-color: #707372;
    box-shadow: none;
    cursor: pointer;

    &::placeholder:not(:disabled, :read-only) {
      color: #707372;
    }

    &:focus:not(:read-only) {
      box-shadow: none;
    }

    &:disabled, &:read-only {
      background-color: #fbfbfb;
      border-color: #c4c4c4;
    }

    &:disabled {
      color: #c4c4c4;
      cursor: not-allowed;
    }
    
    &:hover:not(:read-only, :disabled) {
      background-color: #f2f8fc;
    }
  }
}

.error, .bp4-intent-danger {
  .bp4-input {
    background-color: #fef2f2;
    border-color: #db3737;
    color: #db3737;

    &:hover:not(:read-only, :disabled) {
      background-color: #fef2f2;
    }
  }
}

.eap-date-picker-input,
.eap-date-range-input {
  .bp4-datepicker-content {
    max-width: 440px;

    .bp4-callout {
      margin: 0 5px;
      padding: 0;
      background-color: $accent-white-smoke;
    }
  }

  .bp4-input-left-container { 
    padding: 0 10px;
    height: 100%;  
  }

  .bp4-daterangepicker-shortcuts {
    font-size: 0.875rem;
  }
}

.bp4-datepicker {
  .bp4-daterangepicker-shortcuts {
    font-size: 0.875rem;
    color: colors.$accent-blue-100;
  }
}

.bp4-daterangepicker {
  box-shadow: 0 0 0 rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);
}

.bp4-menu-item.bp4-selected, .bp4-menu-item.bp4-selected:hover, .bp4-menu-item.bp4-selected:active {
  color: colors.$accent-blue-100;
  background-color: colors.$accent-pale-blue;
}

.bp4-menu-item:active, .bp4-menu-item.bp4-active {
  color: colors.$accent-blue-100;
  background-color: colors.$accent-pale-blue;
}

.bp4-menu-item {
  padding: 8px;
  color: colors.$primary-blue;
  border-radius: 5px;

  &:hover {
    color: colors.$primary-blue;
    background-color: colors.$accent-pale-blue;
  }

  &:disabled {
    color: colors.$accent-silver;
  }
}

.bp4-menu-item.bp4-disabled {
  color: colors.$accent-silver !important; /* stylelint-disable-line declaration-no-important */
}

.bp4-menu {
  padding: 8px;
  border-radius: 4px;
}

.bp4-overlay-backdrop {
  background-color: rgba(51, 51, 51, 0.6);
}
