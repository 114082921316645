.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputs {
  display: flex;
  flex: 1;

  > div {
    &:nth-of-type(1) {
      flex: 1;
    }

    &:nth-of-type(2) {
      flex: 2;
      margin: 0 1rem;
    }

    &:nth-of-type(3) {
      flex: 1;
    }
  }
}

.reset {
  margin-top: 10px;
  padding: 2rem;
}

.apply {
  margin-top: 10px;
}
