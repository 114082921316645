@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';
@import 'styles/partials/mixins';

.annualReporting {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
  margin-top: 140px;

  a {
    font-weight: 600;
  }

  &Desc {
    display: flex;

    span {
      margin-left: 12px;
    }
  }

  form {
    > div:nth-of-type(1) {
      margin-bottom: 18px;
    }

    > div:nth-of-type(2) {
      margin-top: 18px;
    }
  }

  .existingEmail {
    width: 100%;

    div {
      font-weight: 600;
    }
  }

  .errorSpan {
    display: block;
    width: 100%;
    min-width: 200px;
    padding-top: 32px;
    color: $accent-red;
    margin-left: 32px;

    @media (min-width: $xlarge) {
      padding-top: 48px;
    }
  }

  .employeeInfo,
  .financialInfo {
    a {
      font-weight: normal;
    }

    .rowFirst {
      @include forms-spacing;
      width: 100%;

      @media (max-width: $xlarge) {
        width: 100%;
      }
    }

    .optionalIndicator {
      color: $accent-grey-06;
    }
  }

  h3 {
    color: $primary-blue;
    font-weight: 600;
  }

  .columnHeading {
    font-weight: 600;
  }

  .rowHeader {
    display: flex;
    justify-content: space-between;
  }

  .splitPeriodRowHeaders {
    svg {
      path {
        &:first-of-type {
          stroke: $white;
        }

        &:last-of-type {
          fill: $white;
        }
      }
    }
  }

  .retroEdit {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .retroEditIcons {
    display: inline-flex;
    justify-content: space-between;
    margin-top: 2px;
    margin-left: 18px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .retroDelete {
    margin-right: 18px;
  }

  .error {
    color: $accent-red;
  }

  .errorPara {
    margin-top: 20px;
    line-height: 20px;
    color: $accent-red;
  }

  .greyedOut {
    color: $accent-grey-06;
  }
}

.annualReportingWithNewNav {
  margin-top: 0;
}
