@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';

.servicePurchaseMemberFilter {
  display: flex;
  align-items: center;
  
  .inputs {
    display: flex;
    flex: 5;
    align-items: flex-end;
  }

  .typeOfPurchaseInput {
    width: 100%;
  }

  .error {
    color: $accent-red;
    margin-top: -25px;
  }

  .dateRange {
    margin: 0 1rem;

    .error {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .reset {
    display: flex;
    justify-content: center;
    flex: 0 1 140px;
    margin-top: 11px;
  
    button {
      padding: 12px 32px;
  
      span {
        margin-left: 12px;
        font-weight: 600;
      }
    }
  }
  
  .apply {
    flex: 0 1 96px;
    margin-top: 11px;
  }

  .searchByMemberInputDesktop {
    width: 100%;
  }
}

@media (max-width: $xlarge) {
  .searchByMemberInputTablet {
    width: 40%;
  }
}
