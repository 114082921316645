@import 'styles/partials/colors';
@import 'styles/partials/constants';
@import 'styles/partials/breakpoints';


.dropdown {
  height: 100%;

  button {
    height: 100%;
    text-align: left;
    border: 0;
    background-color: transparent;
    transition: color .15s ease-in-out;
    color: $accent-light-slate-gray;
    padding: 0;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .container {
    display: flex;
    justify-content: flex-end;
    height: 80px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      @media (max-width: $xlarge) {
        padding: 0 15px;
      }

      span {
        font-size: 17px;
        font-weight: 400;
        color: $primary-black;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .displayList {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $white;
    min-width: 186px;
    box-sizing: border-box;
    border: 1px solid $accent-grey;
    z-index: 2999;
    overflow: auto;
    max-height: calc(100vh - #{$nav-sidebar-width});

    button,
    a,
    .item {
      padding: 5px 24px;
      font-size: 18px;
      line-height: 38px;
      color: $primary-black;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: $accent-mine-shaft;
        background: rgba($secondary-blue, 0.05);
      }
    }

    .divider {
      > hr {
        margin: 0;
      }
    }
  }

  .disabledMenuItem {
    /* stylelint-disable-next-line declaration-no-important */
    color: $accent-silver !important;
  }
}

.icon {
  svg {
    height: 17px;
    margin-left: 22px;
  }

  &-active {
    svg {
      transform: rotate(180deg);
    }
  }
}
