@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';

.linkList {
  border-bottom: 1px solid $accent-grey;

  > button {
    width: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  button.hideLinkUnderlines {
    text-decoration: none;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    height: 50px;
    font-size: 1.4rem;
    padding: 5px;

    @media (max-width: $medium) {
      font-size: 1.1rem;
    }

    @media (max-width: $xlarge) {
      font-size: 1.2rem;
    }

    > div {
      &:last-of-type {
        margin-left: 8px;
      }
    }

    &:hover,
    &:focus {
      background-color: $accent-alice-blue;
    }

    .form {
      font-size: 1.3rem;

      @media (max-width: $xlarge) {
        font-size: 1.2rem;
      }

      .number {
        font-weight: 500;
        color: $secondary-blue;

        &.largeMargin {
          margin-right: 30px;
        }

        &.mediumMargin {
          margin-right: 20px;
        }
      }

      .title {
        font-weight: 500;
        color: $black;
        white-space: nowrap;
        margin-right: 8px;
      }
    }
  }

  &.disabled {
    > button {
      &:hover,
      &:focus {
        cursor: default;
      }
    }

    .container {
      &:hover,
      &:focus {
        background-color: transparent;
        cursor: default;
      }

      .form {
        .number {
          color: $secondary-grey;
        }

        .title {
          color: $secondary-grey;
        }
      }
    }
  }
}
