@import 'styles/partials/colors';

.divider {
  height: 1px;
  width: 100%;
  margin: 12px 0 24px;
  background-color: $accent-grey-08;
  border: 0;

  &.vertical {
    width: 1px;
    height: inherit;
    margin: 0 1.2rem;
  }
}
