@import 'styles/partials/colors';

$primary-brand-yellow100: #f0950c;
$extended-extended-green100: #0f9960;
$extended-extended-blue100: #0078b5;
$extended-extended-red100: #db3737;

$success-green-bg: #ebf7f2;
$warning-orange-bg: #fff7e7;
$error-red-bg: #fef2f2;
$info-blue-bg: #f2f8fc;

.toaster {
  margin-top: 5rem;
  z-index: 1000000001;

  @mixin toast-base {
    min-width: 38rem;
    padding: 1rem;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    box-shadow: unset;

    :global(.bp4-button-group) {
      padding: 0;
      height: 1.5rem;
      width: 1.5rem;
    }

    :global(button.bp4-button) {
      // !important required to overide blueprint's use of !important
      background-color: transparent !important; /* stylelint-disable declaration-no-important */
      padding: 0;
      margin: -2px;
    }
    
    :global(.bp4-button-group.bp4-minimal) {
      align-self: flex-start;
    }

    :global(.bp4-icon > svg:not([fill])) {
      fill: $primary-black;
    }


    :global(.bp4-icon > svg) {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  :global(span.bp4-toast-message) {
    padding: unset;
    margin-left: 1rem;
    font-size: 1.125rem;
    color: $primary-black;
    line-height: 1.25rem;
    font-weight: 400;
  }
  
  > :global(.bp4-toast.bp4-intent-success) {
    @include toast-base;
    border: 3px $extended-extended-green100 solid;
    background-color: $success-green-bg;
  }

  > :global(.bp4-toast.bp4-intent-warning) {
    @include toast-base;
    border: 3px $primary-brand-yellow100 solid;
    background-color: $warning-orange-bg;
  }
  
  > :global(.bp4-toast.bp4-intent-primary) {
    @include toast-base;
    border: 3px $extended-extended-blue100 solid;
    background-color: $info-blue-bg;
  }
  
  > :global(.bp4-toast.bp4-intent-danger) {
    @include toast-base;
    border: 3px $extended-extended-red100 solid;
    background-color: $error-red-bg;
  }
}

.icon {
  width: unset;
  align-self: flex-start;

  > div {
    display: flex;
    align-items: center;
  }

}

