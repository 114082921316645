@import 'styles/partials/colors';

.selectRemittanceTypeForm {
  margin-top: 2rem;
  border: 1px solid $accent-grey;
  padding: 2rem;
  text-align: left;

  label {
    font-size: 18px;
  }

  span {
    max-width: 360px;
  }

  hr {
    margin-top: 2rem;
    border-top: 1px solid $accent-grey;
  }

  .yearMonthDropdown {
    display: flex;

    .month {
      margin-left: 1rem;
      width: 40%;
    }

    .year {
      width: 58%;
    }
  }
}
