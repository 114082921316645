@import 'styles/partials/colors';
@import 'styles/partials/breakpoints';
@import 'styles/partials/mixins';

.formHeader {
  @include static-header(125px);
  z-index: 30;
  padding-right: 1rem;
  padding-left: 1rem;
}

.formHeaderWithNewNav {
  @include static-header-with-new-navheader;
}

.actionBar {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.88rem;

  &.increaseMargin {
    margin-top: 1.6rem;
  }

  &Title {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: left;

    &Num {
      color: $secondary-light-grey;
      margin-right: 8px;
    }

    &Desc {
      color: $secondary-light-grey;
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.headerWrapper {
  .pipe {
    margin-left: 10px;
    margin-right: 10px;
    color: $accent-grey;
  }

  .extraHeaderInfo {
    text-align: left;
    color: $secondary-grey;
    font-size: 16px;
  }

  button {
    width: 79px;
    max-height: 45px;
  }
}

.toolTipList {
  padding: 10px;

  .toolTipItem {
    display: flex;
    vertical-align: center;
    justify-content: space-between;

    .btn {
      margin-bottom: 0;

      .icon svg {
        width: 25px;
        height: 25px;
        margin-top: 3px;
        padding-right: 15px;
      }

      .label {
        font-size: 18px;
      }
    }
  }
}

.noProgressBar {
  height: auto;
  min-height: auto;

  .actionBar {
    margin: 22px 0;
  }
}

.actionBtns {
  display: flex;
  align-items: center;

  .btn {
    width: 20px;
    height: 20px;
    margin: 0 20px;

    .spanText {
      color: $primary-blue;
      font-weight: 400;
      padding-left: 10px;
    }

    &:first-of-type {
      margin-left: 32px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.exit {
  margin-left: 20px;
}

.submitBtns {
  display: flex;
  margin-right: 32px;

  .save {
    display: flex;
    align-items: center;
    margin-right: 20px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-blue;
      font-weight: 400;
      font-size: 18px;
    }
  }

  .review {
    display: flex;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      font-size: 18px;
      white-space: nowrap;

      span {
        margin-right: 12px;
      }
    }
  }
}

.saveStatus {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  color: $primary-blue;

  div {
    display: flex;
  }
}

.success {
  align-items: center;
  color: $black;

  svg {
    margin-right: 13px;
  }
}

.error {
  align-items: center;
  color: $black;

  svg {
    color: $accent-red;
    height: 23px;
    width: 26px;
    margin-right: 5px;
  }
}

.warning {
  align-items: center;
  color: $black;

  svg {
    color: $secondary-yellow;
    height: 23px;
    width: 26px;
    margin-right: 5px;
  }
}

.exceptionsBlock {
  margin-left: 15px;
}
