.inlineButton {
  display: inline;
}

.dropdownActionsButton {
  display: flex;

  .ellipsisBtn {
    display: flex;
    align-items: center;
    margin-right: 12px;
    padding: 20px;
  }
}

.innerContainer {
  overflow-y: scroll;
  max-height: 600px;
}

.toolTipList {
  padding: 10px;

  hr {
    border: 0;
    padding: 0;
    margin: 10px 0;
  }

  .toolTipOption {
    display: flex;
    vertical-align: center;
    justify-content: space-between;

    .btn {
      margin-bottom: 0;

      .icon svg {
        width: 25px;
        height: 25px;
        margin-top: 3px;
        padding-right: 15px;
        box-sizing: content-box;
      }

      .label {
        font-size: 18px;
      }
    }
  }
}
