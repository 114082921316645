@import 'styles/partials/colors';
@import 'styles/partials/fonts';
@import 'styles/partials/breakpoints';

.toolTip {
  margin-left: 10px;
  display: inline-flex;

  &.noMarginLeft {
    margin-left: 0;
  }

  button {
    background: none;
    border: 0;
    padding: 0;

    div {
      display: flex;
    }
  }

  &Icon {
    &:hover {
      opacity: 0.7;
    }

    &.grey {
      svg {
        path:first-of-type {
          stroke: rgba($white, 0.8);
        }

        path:last-of-type {
          fill: rgba($white, 0.8);
        }
      }
    }
  }

  &Label {
    display: block;
    font-family: $heading-font;
    font-size: 24px;
    line-height: 29px;
    color: $primary-blue;
    margin-bottom: 1rem;
  }

  &Content {
    div {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    hr {
      border: 1px solid $border-grey-02;
    }
  }
}

div.borderGrey {
  padding: 0;
  border: 1px $accent-grey solid;
  box-shadow: 0 0 18px rgba($black, 0.25);
  border-radius: 5px;

  .tooltip-inner {
    border-radius: 5px;
  }

  .toolTipLabel {
    color: $white;
    font-family: $body-font;
    font-weight: 500;
    padding: 0.5em 1em;
    font-size: 16px;
    margin-bottom: 0;
    background-color: $secondary-blue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .toolTipContent {
    padding: 0.3em 0.7em;
    color: $secondary-blue;

    button {
      padding: 0.5em;
      color: $secondary-blue;
      text-align: left;
    }
  }
}

.tip {
  color: $black;
  background-color: $white;
  border: 3px solid $primary-blue;
  border-radius: 3px;
  padding: 2rem;
  word-wrap: break-word;
  position: absolute;
  z-index: 2997;

  &.popoverFlickeringFix {
    pointer-events: none;
  }

  &.solidBlue {
    padding: 0.5rem;
  }

  &.borderNone {
    padding: 1rem 1.5rem;
    border-color: $white;
    border-radius: 5px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.25);

    &.thinTooltip {
      padding: 0.5rem;
    }

    a,
    button {
      color: $black;
      font-size: 18px;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .toolTipContent {
      div {
        margin-bottom: 22px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &[x-placement='top'],
  &[x-placement='bottom'],
  &[x-placement='right'],
  &[x-placement='left'] {
    > div:first-of-type {
      border-width: 23px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      position: absolute;
    }

    > div:first-of-type::before {
      content: '';
      border-width: 23px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      position: absolute;
    }
  }

  &[x-placement='top'] {
    margin-bottom: 20px;
    max-width: 500px;

    &.thinTooltip {
      max-width: 375px;

      @media (max-width: $medium) {
        max-width: 90%;
      }
    }

    &.solidBlue {
      background-color: $primary-blue;
      color: $white;
      font-weight: 600;
    }

    &.borderNone {
      > div:first-of-type {
        border-top: 15px solid $white;

        &:first-of-type::before {
          border-top: 15px solid $white;
        }
      }
    }

    > div:first-of-type {
      border-top: 15px solid $primary-blue;
      bottom: -33px;

      &:first-of-type::before {
        border-top: 15px solid $primary-blue;
      }
    }

    > div:first-of-type::before {
      content: '';
      top: -19px;
      left: -15px;
      border-top: 15px solid $white;
    }

    @media (max-width: $medium) {
      max-width: 90%;
    }
  }

  &[x-placement='bottom'] {
    margin-top: 20px;
    max-width: 500px;

    &.thinTooltip {
      max-width: 375px;

      @media (max-width: $medium) {
        max-width: 90%;
      }
    }

    &.solidBlue {
      background-color: $primary-blue;
      color: $white;
      font-weight: 600;

      &:first-of-type::before {
        border-bottom: 15px solid $primary-blue;
      }
    }

    &.borderNone {
      > div:first-of-type {
        border-bottom: 15px solid $white;
      }
    }

    > div:first-of-type {
      border-bottom: 15px solid $primary-blue;
      top: -33px;
    }

    > div:first-of-type::before {
      content: '';
      top: -11px;
      left: -15px;
      border-bottom: 15px solid transparent;
      position: absolute;
    }

    @media (max-width: $medium) {
      max-width: 90%;
    }
  }

  &[x-placement='right'] {
    z-index: 2999;
    margin-left: 20px;
    max-width: 30%;

    &.solidBlue {
      background-color: $primary-blue;
      color: $white;
      font-weight: 600;

      &:first-of-type::before {
        border-right: 15px solid $primary-blue;
      }
    }

    &.borderNone {
      > div:first-of-type {
        border-right: 15px solid $white;
      }
    }

    > div:first-of-type {
      border-right: 15px solid $primary-blue;
      left: -33px;
    }

    > div:first-of-type::before {
      content: '';
      top: -15px;
      left: -11px;
      border-right: 15px solid $white;
    }

    @media (min-width: $medium) {
      max-width: 360px;
    }
  }

  &[x-placement='left'] {
    margin-right: 20px;
    max-width: 20%;

    &.solidBlue {
      background-color: $primary-blue;
      color: $white;
      font-weight: 600;

      &:first-of-type::before {
        border-left: 15px solid $primary-blue;
      }
    }

    &.borderNone {
      > div:first-of-type {
        border-left: 15px solid $white;
      }
    }

    &.borderGrey {
      > div:first-of-type {
        border-left: 19px solid $accent-grey;
      }
    }

    > div:first-of-type {
      border-left: 15px solid $primary-blue;
      right: -33px;
    }

    > div:first-of-type::before {
      content: '';
      top: -15px;
      left: -19px;
      border-left: 15px solid $white;
    }

    @media (min-width: $medium) {
      max-width: 360px;
    }
  }
}
