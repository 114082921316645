@import 'styles/partials/colors';

.retroPayModal {
  display: flex;
  text-align: left;
  flex-direction: column;

  .infoContainer {
    display: flex;
    flex-direction: column;
    background-color: $accent-pale-blue;
    padding: 24px;
    margin-bottom: 30px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.6rem;

    span {
      width: 100%;
    }

    > span:last-of-type {
      font-weight: 600;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .stronger {
    font-weight: 600;
  }

  .secondRow {
    display: flex;
    justify-content: space-between;

    > div {
      margin-right: 20px;
    }

    input {
      width: 183px;
    }

    .calculator {
      display: flex;
      margin-right: 0;
      margin-top: 36px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
      }
    }
  }

  .addAnotherYear {
    button {
      display: flex;
    }
  }

  .errorMessage {
    color: $accent-red;
    margin: .25rem 0 0;
    display: inline-block;
  }

  .totalDiv {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }

  .footer {
    button {
      width: 276px;
    }

    .rc-dialog-footer button {
      width: none;
    }
  }

  .setMargin {
    margin-left: 2rem;
  }

  .hidden {
    display: none;
  }

  .error {
    color: $accent-red;
  }
}
