@import 'styles/partials/colors';

$pseudo-element-size: 16px;
$pseudo-element-offset: -15px;
$border-radius-size: 16px;

.container {
  box-sizing: border-box;
  display: flex;
  padding: 1em 1em 0.5em;
}

.progressBar {
  display: flex;
  width: 100%;
  margin-right: 8px;

  .progressStep {
    display: flex;
    flex-grow: 1;
    color: $white;
    height: 34px;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 5px;
    margin-left: 5px;

    &::after {
      content: '';
      border-top: $pseudo-element-size solid transparent;
      border-bottom: $pseudo-element-size solid transparent;
      border-left: $pseudo-element-size solid $primary-blue;
      position: absolute;
      right: $pseudo-element-offset;
      top: 0;
      z-index: 1;
    }

    &::before {
      content: '';
      border-top: $pseudo-element-size solid transparent;
      border-bottom: $pseudo-element-size solid transparent;
      border-left: $pseudo-element-size solid $white;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:first-of-type::before,
    &:last-of-type::after {
      content: none;
    }

    &:first-of-type {
      border-top-left-radius: $border-radius-size;
      border-bottom-left-radius: $border-radius-size;
      margin: 0;
    }

    &:last-of-type {
      margin: 0;
      border-top-right-radius: $border-radius-size;
      border-bottom-right-radius: $border-radius-size;
    }

  }

  .activeStep {
    background: $secondary-blue;
    font-weight: 600;

    &::after {
      border-left-color: $secondary-blue;
    }
  }

  .completedStep {
    background: $accent-dark-blue;
    font-weight: 600;

    &::after {
      border-left-color: $accent-dark-blue;
    }
  }

  .nextStep {
    font-weight: 400;
    background: $accent-mercury;
    color: $secondary-grey;

    &::after {
      border-left-color: $accent-mercury;
    }
  }
}
