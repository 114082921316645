@import 'styles/partials/colors';

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($white, 0.75);
  z-index: 3000;
  height: 100%;
  width: 100%;

  &.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.hidden {
    display: none;
  }
}

.spinner {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      animation: spin 1400ms linear 0s infinite running;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.text {
  color: $primary-blue;
  margin-left: 13px;
}

.small {
  svg {
    width: 22px;
    height: 22px;
  }
}

.medium {
  svg {
    width: 36px;
    height: 36px;
  }
}

.large {
  svg {
    width: 66px;
    height: 66px;
  }
}
