@import 'styles/partials/colors';

.button {
  border: 1.5px solid $primary-orange;
  background-color: $primary-orange;
  color: $white;
  border-radius: 5px;
  padding: 0.7rem 1.7rem;
  font-weight: 700;
  transition: 0.15s;

  a {
    color: $white;
    font-weight: 700;
    text-decoration: none;
    box-sizing: border-box;

    &:hover,
    &:focus {
      color: $white;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover,
  &:focus {
    background-color: $accent-rust;
    border: 1.5px solid $accent-rust;
  }

  &:focus {
    text-decoration: underline;
  }

  &:disabled {
    background-color: $accent-silver;
    border: 1.5px solid $accent-silver;
    color: $white;
  }

  &.loadingSpinner {
    &:disabled {
      padding: 0.7rem 2rem;
      background-color: $accent-alabster;
      border: 1.5px solid rgba($accent-silver, 0.5);
    }
  }

  &.basic {
    background-color: transparent;
    color: $secondary-blue;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 19px;
    border: 0;
    padding: 0;
    margin: 0;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      color: $primary-blue;
      opacity: 0.7;
      text-decoration: none;
    }

    &:disabled {
      color: $accent-silver;

      div,
      span {
        color: $accent-silver;
      }
    }

    &:not(.disableSvgStroke) {
      svg {
        path {
          stroke: $secondary-blue;
        }
      }

      &:hover,
      &:focus {
        svg {
          path {
            stroke: $primary-blue;
          }
        }
      }

      &:disabled {
        svg {
          path {
            stroke: $accent-silver;
          }
        }
      }
    }
  }

  &.solidBlue {
    background-color: $primary-blue;
    border: 1.5px solid $primary-blue;
    color: $white;

    &:hover,
    &:focus {
      background-color: $accent-blue;
      border: 1.5px solid $accent-blue;
    }

    &:focus {
      text-decoration: underline;
    }

    &:disabled {
      background-color: $accent-silver;
      border: 1.5px solid $accent-silver;
      color: $white;
    }
  }

  &.outlineOrange {
    background-color: transparent;
    border: 1.5px solid $primary-orange;
    color: $primary-orange;
    font-weight: 600;

    &:hover,
    &:focus {
      background-color: $accent-sea-shell;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &.outlineBlue {
    background-color: transparent;
    border: 1.5px solid $primary-blue;
    color: $primary-blue;
    font-weight: 600;

    &:hover,
    &:focus {
      background-color: $accent-alice-blue;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &.outlineWhite {
    background-color: $white;
    border: 1px solid $accent-grey;
    color: $black;
    font-weight: 400;

    &:hover,
    &:focus {
      background-color: $accent-alice-blue;
    }

    &:focus {
      text-decoration: underline;
    }
  }

  &.outlineBlue,
  &.outlineOrange,
  &.outlineWhite {
    &:disabled {
      color: $accent-silver;
      border: 1px solid $accent-silver;
    }
  }

  &.link {
    border: 0;
    background-color: transparent;
    font-weight: normal;
    transition: color 0.15s ease-in-out;
    color: $secondary-blue;
    padding: 0;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: $primary-blue;
    }

    &:disabled {
      cursor: default;
      text-decoration: none;
      color: $accent-silver;
    }
  }

  &.linkOrange {
    border: 0;
    background-color: transparent;
    font-weight: normal;
    transition: color 0.15s ease-in-out;
    color: $primary-orange;
    padding: 0;
    text-decoration: underline;

    &:focus,
    &:hover {
      color: $primary-orange;
    }

    &:disabled {
      cursor: default;
      text-decoration: none;
      color: $accent-silver;
    }
  }

  @media print {
    display: none;
  }
}
