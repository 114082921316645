@import 'styles/partials/colors';
@import 'styles/partials/fonts';
@import 'styles/partials/breakpoints';

.textInputContainer {
  width: 100%;
  box-sizing: border-box;

  select {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }
}

.inputWrapper {
  position: relative;
}

.textInput {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  transition: 0.15s;
  color: $primary-black;
  background-color: $accent-grey-09;
  border: 1px solid $secondary-grey;
  -moz-appearance: textfield; // stylelint-disable-line property-no-vendor-prefix
  margin-bottom: 25px;

  &ClearButton {
    padding-right: 34px;
  }

  &.specialFocus {
    box-shadow: 0 0 0 0.2rem $primary-blue;

    &:focus,
    &:hover {
      outline: unset;
      background-color: unset;
      box-shadow: 0 0 0 0.2rem $primary-blue;
    }
  }

  &:focus,
  &:hover {
    outline: unset;
    background-color: $accent-alice-blue;
  }

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 0.2rem rgba($accent-azure, 0.25);
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &.isFieldError {
    border-color: $accent-red;
    background-color: $accent-white-rose;
  }

  &::placeholder {
    color: $accent-silver;
  }

  &[placeholder] {
    text-overflow: ellipsis;
  }

  &[disabled] {
    background-color: $accent-grey-02;
    border: 1px solid $accent-silver;
    color: $secondary-grey;
  }
}

.inputButton {
  position: absolute;
  right: 7px;
  top: 14px;
  border: 0;
  background: none;
  padding: 0;

  &Icon {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &.isDisabled {
    &:hover {
      opacity: 1;
    }
  }
}

.selectInput {
  position: absolute;
  height: 48px;

  select {
    background-color: $accent-grey-04;
    top: 0;
    position: absolute;
  }
}

.currencyLabel,
.percentageLabel {
  position: absolute;
  top: 0;
  height: 48px;
  width: 25px;
  font-size: 20px;
  line-height: 26px;
  color: $accent-grey-06;
  user-select: none;

  > span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.percentageLabel {
  right: 0;
  width: 40px;
  color: $primary-black;
  background-color: $accent-grey-04;
  box-sizing: border-box;
  border: 1px solid $secondary-grey;
  border-left-color: $accent-silver;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  > span {
    justify-content: center;
  }
}

.calculator {
  position: absolute;
  right: 12px;
  top: 12px;
}

.errorMessage {
  color: $accent-red;
  margin-top: -25px;
}

.labelText {
  display: inline-flex;
  font-family: $body-font;
  line-height: 29px;
  font-weight: 600;
  margin-bottom: 8px;
  white-space: nowrap;

  .optional {
    font-weight: 400;
    margin-left: 3px;
  }
}

@media (max-width: $large) {
  .labelText {
    font-size: 15px;
  }
}
