@import './colors';
@import './breakpoints';

$main-nav-header-height: 80px;

@mixin forms-spacing($spacing: 15px, $margin-bottom: 1rem) {
  display: flex;

  > div {
    margin: 0 $spacing $margin-bottom;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@mixin date-form($margin-bottom: 0) {
  @include forms-spacing(7px, $margin-bottom);
  flex-wrap: wrap;
  min-width: 352px;

  > div {
    &:nth-of-type(1) {
      width: 126px;
    }

    &:nth-of-type(2) {
      width: 86px;
    }

    &:nth-of-type(3) {
      width: 105px;
    }

    &:not(:first-of-type) {
      margin-top: 37px;
    }

    &:nth-of-type(4) {
      flex-basis: 100%;
      margin: -27px 0 0;
    }
  }
}

@mixin static-header($height) {
  display: flex;
  position: fixed;
  top: $main-nav-header-height;
  z-index: 1;
  flex-direction: column;
  width: calc(100vw - 80px);
  min-height: $height;
  background-color: $white;
  border-top: 1px solid $accent-grey;
  border-bottom: 1px solid $accent-grey;
  box-shadow: 0 4px 4px rgba($black, 0.07);
  box-sizing: border-box;
}

@mixin static-header-with-new-navheader {
  position: sticky;
  top: $main-nav-header-height;
}

@mixin ellipsis-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin hidden {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
}

@mixin highlight-changes {
  background-color: $accent-highlight;
  padding: 0.4rem 2rem;
  border-radius: 0.6rem;
  position: relative;
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    height: 0.65rem;
    width: 0.65rem;
    background-color: $primary-orange;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0.6em;
    transform: translateY(-50%);
  }
}

// targets specific cases where generic basic button styling should not be applied to svgs embed inside buttons
@mixin override-btn-svg-style {
  > button {
    svg {
      path {
        /* stylelint-disable-next-line declaration-no-important */
        stroke: none !important;
      }
    }
  }

  > button[disabled] {
    path {
      /* stylelint-disable-next-line declaration-no-important */
      fill: $accent-silver !important;
    }
  }
}
