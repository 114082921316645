.messagesFilter {
  display: flex;
  align-items: center;

  .inputs {
    display: flex;
    flex: 6;

    > div {
      margin: 0 7px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:nth-of-type(1) {
        flex: 1;
      }

      &:nth-of-type(2) {
        flex: 2;
      }

      &:nth-of-type(3) {
        flex: 2;
      }

      &:nth-of-type(4) {
        flex: 2;
      }
    }
  }

  .reset,
  .apply {
    margin-top: 11px;
  }

  .reset {
    flex: 0 1 140px;
  }

  .apply {
    flex: 0 1 96px;
  }
}
