@import 'styles/partials/colors';

.dateRangeInput {
  display: flex;
  align-items: flex-end;

  label,
  .dateRangeInputFrom input,
  .dateRangeInputTo input {
    min-width: 154px;
  }

  .toText {
    line-height: 6rem;
    font-weight: 600;
    margin: 0 0.7rem;
  }
}

.error {
  color: $accent-red;
  margin-top: -25px;
}

.isLarge {
  label,
  .dateRangeInputFrom input,
  .dateRangeInputTo input {
    min-width: 260px;
  }
}

.isMedium {
  label,
  .dateRangeInputFrom input,
  .dateRangeInputTo input {
    min-width: 200px;
  }
}

.isSmall {
  label,
  .dateRangeInputFrom input,
  .dateRangeInputTo input {
    min-width: 130px;
  }
}

.isXsmall {
  label,
  .dateRangeInputFrom input,
  .dateRangeInputTo input {
    min-width: 100px;
    max-width: 132px;
  }

  .dateRangeInputFrom,
  .dateRangeInputTo {
    max-width: 160px;
    align-items: flex-start;
    height: 115px;
  }
}
