.overrideContainer {
  display: flex;
  flex-direction: column;
}

.overrideRow {
  display: flex;
  justify-content: space-between;

  input {
    top: 5px;
  }

  > div:last-of-type {
    margin-left: 8px;
    min-height: 34px;

    label {
      margin-top: 4px;
    }
  }
}

.overrideBody {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;

  .select {
    width: 48%;
    margin-right: 20px;
  }

  .textarea {
    width: 48%;
  }
}

span {
  &.itemText {
    font-weight: normal;
  }
}
