@import 'styles/partials/colors';

.checkbox {
  display: flex;
  height: 100%;
  gap: 0.5rem;
  align-items: center;

  &Container {
    position: relative;
    padding-left: 39px;
    display: flex;
    align-items: center;

    &:hover .checkboxIcon {
      background-color: $accent-grey;

      &.alt {
        background-color: $accent-dark-blue;
      }
    }
  }

  &Description {
    width: 100%;
    margin: 0;
  }

  &Icon {
    box-sizing: content-box;
    display: block;
    position: absolute;
    top: 3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $accent-snow;
    border: 2px solid $primary-blue;
    border-radius: 4px;

    &.noTop {
      top: -1px;
    }

    &.alt {
      background-color: $primary-blue;
      border: 2px solid $primary-blue;
    }

    &.altBorder {
      border-color: $secondary-grey;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 7px;
      height: 12px;
      border: solid transparent;
      border-width: 0 3px 3px 0;
      background-color: transparent;
      transform: rotate(45deg);
    }
  }

  &Label {
    display: flex;
    margin-top: 4px;
  }
}

.noLeftPadding {
  padding-left: 0;
}

.titleWithMargin {
  margin-left: 10px;
}

.disabledCheckboxText {
  color: $accent-grey-03;
}

.hiddenCheckbox {
  position: absolute;
  top: 5px;
  left: 2px;
  height: 24px;
  width: 24px;
  z-index: 0;

  &.noTop {
    outline: unset;
    top: 1px;
  }

  &:focus ~ .checkboxIcon {
    outline: unset;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:checked ~ .checkboxIcon {
    background-color: $primary-blue;

    &.altBorder {
      border-color: $primary-blue;
    }

    &::after {
      border: solid $white;
      border-width: 0 3px 3px 0;
    }
  }

  &[disabled] ~ .checkboxIcon {
    background-color: $accent-grey-03;
    border: 2px solid $accent-grey-03;
  }

  &.isFieldError ~ .checkboxIcon {
    border-color: $accent-red;
    background-color: $accent-white-rose;
  }
}

.errorMessage {
  color: $accent-red;
  margin: 0 0 0 35px;
}

.errorMessage:empty {
  display: none;
}

.labelTop {
  top: -3px;
  position: relative;
}
