@import 'styles/partials/colors';
@import 'styles/partials/fonts';
@import 'styles/partials/constants';

.containerBorder {
  @media print {
    border: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
  box-sizing: border-box;
  margin: $container-spacing;
  background: $white;
  border: 1px solid $accent-grey;

  .header {
    @media print {
      padding: 0;
    }
    background-color: $secondary-blue;
    font-weight: 700;
    color: $white;
    padding: 0.75rem 1.5rem;
  }

  .content {
    @media print {
      padding: 0;
    }
    padding: 2rem 3rem;
  }

  h2 {
    margin: 0.67em 0 1em;
  }

  &.accent {
    border-top: 6px solid $secondary-blue;
  }

  &.noMargin {
    margin: 0;
  }

  &.marginTop {
    margin-top: 1.2rem;
  }

  &.marginBottom {
    margin-bottom: 1.2rem;
  }

  &.marginLeft {
    margin-left: 1.2rem;
  }

  &.marginRight {
    margin-right: 1.2rem;
  }

  &.noPadding {
    .content {
      padding: 0;
    }
  }

  &.customPadding {
    .content {
      padding: 16px 24px;
    }
  }

  &.noBorder {
    border: none;
  }


}
