@import 'styles/partials/colors';
@import 'styles/partials/fonts';
@import 'styles/partials/breakpoints';
@import 'styles/partials/mixins';

.selectInput {
  display: block;
  width: 100%;
  padding: 0 38px 0 3px;
  text-indent: 10px;
  height: 3rem;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: $primary-black;
  border: 1px solid $secondary-grey;
  appearance: none;
  -moz-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  -webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
  background: url('/images/chevron-down.svg') no-repeat right $accent-grey-09;
  background-position-x: calc(100% - 18px);
  margin-bottom: 25px;

  &Container {
    width: 100%;

    select {
      @include ellipsis-overflow;
    }

    select::-ms-expand {
      display: none;
    }
  }

  &:focus,
  &:hover {
    outline: unset;
    background-color: $accent-alice-blue;
  }

  &:focus {
    outline: unset;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &.isFieldError {
    border-color: $accent-red;
    background-color: $accent-white-rose;
  }

  &[disabled] {
    background-color: $accent-grey-02;
    border: 1px solid $accent-silver;
    color: $secondary-grey;
  }
}

.errorMessage {
  color: $accent-red;
  margin-top: -25px;
}

.noMarginTop {
  margin-top: 0;
}

.labelText {
  font-family: $body-font;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 0.5rem;
  display: inline-flex;
  white-space: nowrap;
}

.labelTTWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    padding-left: 0.5rem;
  }
}

.inline {
  display: flex;
  align-items: center;

  select {
    margin: 0;
    margin-left: 45px;
    width: 375px;
  }
}

.visuallyHidden {
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0); /* for IE only */
  clip-path: inset(50%);
  position: absolute;
  white-space: nowrap;  
}

@media (max-width: $large) {
  .labelText {
    font-size: 15px;
  }
}
