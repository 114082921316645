@import 'styles/partials/fonts';
@import 'styles/partials/colors';

.annualReportingReview {
  .notAvailable {
    font-weight: normal;
    color: $accent-grey;
  }

  .label {
    font-weight: 600;
  }

  .gridWrapper {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.4rem;
  }

  .twoColumns {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 0.7rem;
  }
}
