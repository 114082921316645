@import 'styles/partials/colors';

.container {
  width: 100%;
  box-sizing: border-box;
}

.emailAddressValid {
  display: flex;

  > div:last-of-type {
    margin-left: 14px;
  }
}

.warning {
  svg {
    height: 24px;
    width: 24px;
    color: $secondary-yellow;
  }
}

.danger {
  svg {
    height: 24px;
    width: 24px;
    color: $accent-red;
  }
}
