.reset {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 12px 32px;

    span {
      margin-left: 12px;
      font-weight: 600;
    }
  }
}
